.day {
  cursor: no-drop;
}
.modalTitle {
  font-size: 1.3rem;
  font-weight: 500px;
}
.modalClose {
  color: #ff008a;
  &:hover {
    background-color: #ff008a;
    color: white;
    transition: "all 0.2s ease-in-out 0s";
  }
}
.box {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.text {
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}
